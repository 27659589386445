<template>
  <div class="list-manage my-collection">
    <section class="list-manage-table">
      <div class="table-title"><span class="title">我的收藏</span></div>
      <div class="thead">
        <span class="th">设备</span>
        <span class="th">地点</span>
        <span class="th">租赁方式</span>
        <span class="th">操作</span>
      </div>
      <div class="tbody" v-loading="loading">
        <template v-if="list.length != 0">
          <div class="list-item" v-for="(item,index) in list" :key="index" >
          <div class="list-item-header"><span class="order-number">时间：{{item.create_time}}</span></div>
          <div class="list-item-main">
            <div class="td device">
              <el-image :src="item.show_img" style="width: 60px;height: 60px; margin-right: 11px;">
                <div slot="error" class="image-slot">
                  <img src="~@/assets/images/temp/temp_03.png" class="device-pic" alt="">
                </div>
              </el-image>
              {{item.name}}
              </div>
            <div class="td">{{item.address}}</div>
            <div class="td">{{item.device_rental_fee[0].rental_fee}}/{{item.device_rental_fee[0].rental_type_name}}</div>
            <div class="td">
              <span class="btn" @click="gotoDetail(item.device_lease_id)">查看</span>
              <span class="btn btn-red" @click="itemDelete(item.device_lease_id)">删除</span>
            </div>
          </div>
          </div>
        </template>
        <div v-else-if="list.length === 0 && !loading"  style="text-align: center;height: 40px;line-height: 40px;font-size: 18px;">暂无数据</div>
      </div>
    </section>
    <div class="pagination">
      <el-pagination
        background
        layout="total,prev, pager, next,jumper"
        :page-size="page.pageSize"
        :current-page="page.pageNumber"
        :total="total.totalRow"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { col_list,col_del } from '@/api/contract'
import { getuser } from "@/api/user"
  import kits from "@/kits"
  export default {
    data() {
      return {
        user:{},
        list:[],
        page: {
          pageNumber: 1,
          pageSize: 10,
        },
        total:{
          totalPage:null,
          totalRow:null
        },
        loading:true
      };
    },
    created(){
      this.user = getuser();
      this.getlist()
    },
    methods: {
      getlist(){
        col_list( {inputs:{},pagination:this.page}).then(res => {
          console.log(res);
          this.list = res.data.list
          this.total.totalRow = res.data.totalRow
            for(let i = 0;i < this.list.length;i++) {
              if(this.list[i].show_img) {this.list[i].show_img = kits.img.url(this.list[i].show_img)}
              this.list[i].device_rental_fee = JSON.parse(this.list[i].device_rental_fee_json)
              for(let j = 0;j < this.list[i].device_rental_fee.length;j++ ) {
                this.list[i].device_rental_fee[j].rental_type_name = this.list[i].device_rental_fee[j].rental_type == 0 ? '日' : this.list[i].device_rental_fee[j].rental_type == 1 ? '月' : this.list[i].device_rental_fee[j].rental_type == 2 ? '半年' : '年'
            }
          }
          this.loading = false
        })
      },
      handleCurrentChange(val) {
        this.page.pageNumber = val
        this.getlist()
      },
      gotoDetail(idd){
        this.$router.push({path:'/deviceDetails',query:{id:idd}})
      },
      itemDelete(oid) {
        this.$confirm('确认是否删除改收藏?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          col_del({inputs:{device_lease_id:oid}}).then(res =>{
          if (res.data == 1) {
            this.$message.success('删除成功')
            setTimeout( ()=> {
              location.reload()
            },500)
            setTimeout( ()=> {
              window,scroll(0,0)
            },800)
          }else {
            this.$message.warning('删除失败')
          }
        })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });



      }
    }
  }
</script>

<style scoped>
</style>
